<template>
  <div>
    <v-card outlined class="vendor-products d-flex flex-column">
      <div class="pl-lg-9 px-sm-7 py-sm-8 py-3 px-3 d-flex align-center">
        <div>
          <div class="text-h6 font-weight-bold">Products</div>
          <div class="text--text">{{ data.result ? data.result.length : 0 }} products</div>
        </div>
        <v-spacer></v-spacer>
        <v-card flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="450">
          <v-text-field
            class="field46 mt-sm-0 mt-2"
            v-model="search"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            hide-details
            outlined
            dense
            required
            color="primary"
            height="46"
            @change="(page = 1), getData()"
          ></v-text-field>
          <v-btn @click="modal = true" class="primary mt-sm-0 mt-2 ms-4" depressed large width="136">Add new</v-btn>
        </v-card>
      </div>
      <v-divider></v-divider>
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="headers"
        :items="data.result"
        item-key="id"
        :items-per-page="-1"
        class="d-md-block d-none"
      >
        <template v-slot:header.status>
          Status
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="status != 'all' ? 'primary white--text' : ''">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :class="status == item.id ? 'primary--text font-weight-bold' : ''"
                class="text-body-2"
                link
                v-for="item in itemsStatus"
                :key="item.id"
                @click="(status = item.id), getData()"
                style="min-height: 36px"
              >
                {{ item.title }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id" @click="openItem(item.id)" class="link">
              <td>
                <div class="d-flex align-center py-3">
                  <v-img
                    v-if="item.media ? item.media.length : false"
                    class="me-5"
                    cover
                    width="100%"
                    max-width="72"
                    height="72"
                    :src="item.media[0].url"
                  >
                  </v-img>
                  <div>
                    <div class="font-weight-bold text-truncate">{{ item.title_en }}</div>
                    <div class="text-truncate" style="max-width: 30vw">{{ item.description_en }}</div>
                  </div>
                </div>
              </td>
              <td>SAR {{ item.price / 100 }}</td>
              <td>
                <v-icon color="primary">{{ item.status == 'listed' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                {{ item.status == 'listed' ? 'Listed' : 'Unlisted' }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div class="d-block d-md-none">
        <div v-for="item in data.result" :key="item.id" class="px-4 pt-2" @click="openItem(item.id)">
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Product</div>
            <div class="d-flex align-center py-3">
              <v-img
                v-if="item.media ? item.media.length : false"
                class="me-5"
                cover
                width="100%"
                max-width="72"
                height="72"
                :src="item.media[0].url"
              >
              </v-img>
              <div>
                <div class="font-weight-bold text-truncate">{{ item.title_en }}</div>
                <div class="text-truncate" style="max-width: 30vw">{{ item.description_en }}</div>
              </div>
            </div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Price</div>
            <div>SAR {{ item.price / 100 }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Status</div>
            <v-icon color="primary">{{ item.status == 'listed' ? 'mdi-check' : 'mdi-close' }}</v-icon>
            {{ item.status == 'listed' ? 'Listed' : 'Unlisted' }}
          </div>
          <v-divider></v-divider>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-divider v-if="data.pages"></v-divider>
      <div class="py-2 px-sm-5 px-3 d-flex align-center justify-center flex-wrap" v-if="data.pages">
        <v-spacer></v-spacer>
        <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
        <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <!-- <v-spacer></v-spacer>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 py-3 px-3 text-end">
        <v-btn @click="updateItem" class="rounded" depressed color="primary" large width="136">Save</v-btn>
      </div> -->
    </v-card>
    <v-dialog v-model="modal" max-width="700">
      <v-card flat outlined>
        <div class="font-weight-bold mb-3 mt-4 text-center">Add product</div>
        <v-card outlined tile color="px-10 pt-6">
          <div class="text--text mb-2 mt-n2">Provide information in English and Arabic</div>
          <div class="d-flex flex-md-row flex-column multi-direct">
            <v-textarea
              class="field46 width100 me-md-3"
              v-model="modalData.title_en"
              placeholder="Title"
              :error-messages="titleEnErrors"
              auto-grow
              rows="1"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <v-textarea
              class="field46 width100 mt-md-0 mt-n2 ms-md-3"
              v-model="modalData.title_ar"
              placeholder="عنوان"
              :error-messages="titleArErrors"
              auto-grow
              rows="1"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
          </div>
          <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
            <v-textarea
              class="width100 me-md-3"
              v-model="modalData.description_en"
              placeholder="Description"
              :error-messages="desEnErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <v-textarea
              class="width100 mt-md-0 mt-n2 ms-md-3"
              v-model="modalData.description_ar"
              placeholder="وصف كامل"
              :error-messages="desArErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
          </div>
          <div class="text--text mb-3 mt-n2">Price</div>
          <v-text-field
            class="field46 mt-n2 price-field"
            v-model="modalData.priceR"
            placeholder="0"
            suffix="SAR"
            type="number"
            :error-messages="priceErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" class="rounded" depressed width="106" height="30" outlined>Cancel</v-btn>
          <v-btn @click="create" class="ms-3 rounded" depressed width="106" height="30" color="primary">Add</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalData: {},
      status: this.$route.query.status || 'all',
      search: this.$route.query.src || '',
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Product', value: 'product', sortable: false },
        { text: 'Price', value: 'price', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ],
      itemsStatus: [
        { id: 'all', title: 'All' },
        { id: 'listed', title: 'Listed' },
        { id: 'unlisted', title: 'Unlisted' },
      ],
      statusError: null,
      error: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    }, 300);
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'Vendor product created successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openItem(id) {
      this.$router.push(`/vendor/products/${id}`);
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      this.statusError = null;
      await this.$store
        .dispatch('getVendorProductsList', {
          vendor: this.profile.product_vendor.id,
          search: this.search,
          status: this.status,
          page: this.page,
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async create() {
      this.error = [];
      this.modalData.price = this.modalData.priceR * 100;
      this.modalData.vendor = this.$route.params.vendorId;
      await this.$store
        .dispatch('createVendorProduct', this.modalData)
        .then((res) => {
          this.modal = false;
          this.data.result.unshift(res.data);
          this.notifi('create');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.vendorProductsList;
    },
    profile() {
      return this.$store.getters.profile;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setVendorProductsList');
  },
};
</script>

<style lang="scss">
.vendor-products {
  min-height: calc(100vh - 172px);
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
}
.price-field {
  .v-input__slot {
    max-width: 156px !important;
  }
}
</style>
